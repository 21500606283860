import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import MetisMenu from "metismenujs";

import SimpleBar from "simplebar-react";
import {
  DropdownItem,
  DropdownMenu,
  Dropdown,
  DropdownToggle,
  Navbar,NavbarToggler,NavbarBrand,Nav,NavItem,NavLink,Container, Row, Col,Badge,
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button
} from "reactstrap";

import AdminMenu from './AdminMenu';
import TeacherMenu from './TeacherMenu';
import HeadTeacherMenu from './HeadTeacherMenu';
import PeaMenu from './PeaMenu';
import ClusterManagerMenu from './ClusterManagerMenu';
import DemMenu from './DemMenu';
import DivisionManagerMenu from './DivisionManagerMenu';
import InspectorMenu from './InspectorMenu';
import MinistryHQMenu from './MinistryHQMenu';
import PupilMenu from './PupilMenu';
import StudentMenu from './StudentMenu';
import GuardianMenu from './GuardianMenu';
import PTAMenu from './PTAMenu';

const SidebarContent = props => {
	
        const user =  sessionStorage.getItem("uname");
        const salute =  sessionStorage.getItem("sal");
        const role =  sessionStorage.getItem("urole");

        if(role == 'System Administrator'){
            return(<AdminMenu />);
        } else if(role == 'Teacher'){
          return(<TeacherMenu />);
        } else if(role == 'Head Teacher' || role == 'Deputy Head') {
          return(<HeadTeacherMenu />);
        } else if(role == 'PEA'){
          return(<PeaMenu />);
        } else if (role == 'Secondary Advisor'){
          return(<ClusterManagerMenu />);
        } else if (role == 'DEM'){
          return(<DemMenu />);
        } else if (role == 'Division Manager'){
          return(<DivisionManagerMenu />);
        } else if (role == 'Inspector' || role == 'DSE'){
          return(<InspectorMenu />);
        } else if (role == 'Ministry HQ'){
          return(<MinistryHQMenu />);
        } else if (role == 'Pupil'){
          return(<PupilMenu />);
        } else if (role == 'Student'){
          return(<StudentMenu />);
        } else if (role == 'Guardian'){
          return(<GuardianMenu />);
        } else if (role == 'PTA'){
          return(<PTAMenu />);
        } 



  
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    if (this.props.type !== "condensed" || this.props.isMobile) {
      new MetisMenu("#side-menu");

      var matchingMenuItem = null;
      var ul = document.getElementById("side-menu");
      var items = ul.getElementsByTagName("a");
      for (var i = 0; i < items.length; ++i) {
        if (this.props.location.pathname === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        this.activateParentDropdown(matchingMenuItem);
      }
    }
  }

  activateParentDropdown = item => {
    item.classList.add("mm-active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active"); // li
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        {this.props.type !== "condensed" ? (
          <SimpleBar style={{ maxHeight: "100%" }}>
            <SidebarContent />
          </SimpleBar>
        ) : (
          <SidebarContent />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Sidebar);
