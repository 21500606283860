import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, InputGroupText, InputGroup, Row, FormFeedback, Col, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Form, FormGroup, Label, Input, ListGroupItem, ListGroup } from 'reactstrap';
import { MDBDataTable } from "mdbreact";
import MaterialTable from '@material-table/core';
import axios from 'axios';
import logo from "../../../assets/images/gov-logo.png";

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';

import { Add, Edit, Delete } from '@material-ui/icons';
import swal from 'sweetalert';
import moment from 'moment';

import backend_url from "../../../routes/API_URL";
import Loader from "../../../components/Loader";

const API_URL = backend_url['api_url'];

const initialState = {
	//Get logged user role
	role: window.sessionStorage.getItem("urole"),
	modalVisible: false,
	modalAdminVisible: false,
	EditModalVisible: false,
	EditAdminModalVisible: false,
	emis_id: '',
	zone_id: '',
	district_id: '',
	division_id: '',
	visit_id: '',
	sector_id: window.sessionStorage.getItem("sector"),
	head_teacher: '',
	head_teacher_phone: '',
	head_teacher_email: '',
	present_visitation_date: moment().format("YYYY-MM-DD"),
	prev_visitation_date: '',
	lead_inspector_id: '',
	first_inspector_id: '',
	second_inspector_id: '',
	third_inspector_id: '',
	fourth_inspector_id: '',
	school_address: '',
	establishment_year: '',
	establishment:'',
	enrolment_boys: '',
	enrolment_girls: '',
	attendance_boys: '',
	attendance_girls: '',
	number_of_teachers: '',
	unqualified_teachers: '',
	action_plan_date: '',
	next_inspection_date: '',
	lead_advisor_id: '',
	govt_chair_id: '',
	visit_type_id: 1,
	bearer_auth: window.sessionStorage.getItem('token'),
	logged_user_id: window.sessionStorage.getItem('uid'),
	Visits: [],
	VisitTypes: [],
	Users: [],
	Sectors: [],
	Districts: [],
	Zones: [],
	Divisions: [],
	Schools: [],
	years: [],
	expiry_date: '',
	visit: {},
	ExpiryVisitDate: '',
	expiryDateMessage: '',
	toggleCalendar: false,
	showOptions: true,
	juridistiction: '',
	loading: '',
	isExpired: false,
	activeStep: 0,
	completed: {}
}

class InspectionVisits extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...initialState,
			bearer_auth: window.sessionStorage.getItem('token'),
			logged_user_id: window.sessionStorage.getItem('uid'),
			sector_id: window.sessionStorage.getItem("sector"),
			present_visitation_date: moment().format("YYYY-MM-DD"),
			role: window.sessionStorage.getItem("urole")
		}
		//Toggle modal
		this.toggle = this.toggle.bind(this);
		this.toggleAdminEdit = this.toggleAdminEdit.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.addInspectionVisit = this.addInspectionVisit.bind(this);
		this.editInspectionVisit = this.editInspectionVisit.bind(this);
		this.updateInspectionVisit = this.updateInspectionVisit.bind(this);
		this.deleteInspectionVisit = this.deleteInspectionVisit.bind(this);

		this.filterDistrictsBeforeLoad = this.filterDistrictsBeforeLoad.bind(this);
		this.filterDistrictsAfterLoad = this.filterDistrictsAfterLoad.bind(this);
		this.filterZonesBeforeLoad = this.filterZonesBeforeLoad.bind(this);
		this.filterZonesAfterLoad = this.filterZonesAfterLoad.bind(this);
		this.filterSchoolsBeforeLoad = this.filterSchoolsBeforeLoad.bind(this);
		this.filterSchoolsAfterLoad = this.filterSchoolsAfterLoad.bind(this);
		this.getRecentVisitBySchool = this.getRecentVisitBySchool.bind(this);
		this.getYears = this.getYears.bind(this);
		this.toggleUpdateExpiryDate = this.toggleUpdateExpiryDate.bind(this);
		this.updateExpiryDate = this.updateExpiryDate.bind(this);
		this.setupData = this.setupData.bind(this);

		this.setId = this.setId.bind(this);
	}

	//Handle the display of the modal
	toggle() {
			this.setState({
				modalVisible: !this.state.modalVisible,
				//Defaults the state
				sector_id: sessionStorage.getItem('sector'),
				emis_id: '',
				zone_id: '',
				district_id: '',
				division_id: '',
				visit_id: '',
				head_teacher: '',
				head_teacher_phone: '',
				phone_number: '',
				head_teacher_email: '',
				present_visitation_date: moment().format("YYYY-MM-DD"),
				prev_visitation_date: '',
				lead_inspector_id: '',
				first_inspector_id: '',
				second_inspector_id: '',
				third_inspector_id: '',
				fourth_inspector_id: '',
				school_address: '',
				establishment_year: '',
				enrolment_boys: '',
				enrolment_girls: '',
				attendance_boys: '',
				attendance_girls: '',
				number_of_teachers: '',
				unqualified_teachers: '',
				action_plan_date: '',
				next_inspection_date: '',
				lead_advisor_id: '',
				govt_chair_id: '',
				visit_type_id: 1,
				EmisIdState: '',
				ZoneIdState: '',
				DistrictIdState: '',
				DivisionIdState: '',
				HeadTeacherIdState: '',
				presentDateState: '',
				prevDateState: '',
				leadInspectorIdState: '',
				firstInspectorIdState: '',
				secondInspectorIdState: '',
				thirdInspectorIdState: '',
				fourthInspectorIdState: '',
				SchoolAddressState: '',
				EstYearState: '',
				EstState: '',
				EnrolledBoysState: '',
				EnrolledGirlsState: '',
				AttendanceBoysState: '',
				AttendanceGirlsState: '',
				TotalTeachersState: '',
				UnqualifiedTeachersState: '',
				pubDateState: '',
				actionDateState: '',
				nextInspectionDateState: '',
				leadAdvisorIdState: '',
				govChairIdState: '',
				visitTypeIdState: '',
				activeStep: 0,
				completed: {}
			});
	}
	toggleAdminEdit(visit) {
		if (this.state.EditAdminModalVisible == false) {
			this.setState({ EditAdminModalVisible: true, visit: visit, expiry_date: visit.expiry_date });
		} else {
			this.setState({
				EditAdminModalVisible: false,
				//Defaults the state
			});
		}
	}

	toggleUpdateExpiryDate() {
		this.setState({
			toggleCalendar: true,
			showOptions: false
		})
	}

	//Setting Edit ID
	setId(e) {
		this.setState({
			[e.target.id]: e.target.value
		});
	}

	updateExpiryDate(e) {
		e.preventDefault();
		axios.patch(API_URL + `/inspection_visits/update/${this.state.visit.visit_id}`, { expiry_date: this.state.expiry_date }, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
			.then(response => {
				//Conditional based on backed responses . Introduced for error handling via message passing with REST
				if (response.status == 200) {
					this.setState({
						EditAdminModalVisible: false,
						showOptions: true,
						toggleCalendar: false,
						expiry_date: ''
						//loading : false,
					});
					swal({
						title: "",
						text: "Visit updated successfully!",
						icon: "success",
						timer: 2500,
						buttons: false
					});
					axios.get(API_URL + `/inspection_visits`,
						{ headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }
					).then(
						(visit) => {
							this.setState({
								Visits: visit.data
							})
						}
					)
				}
			}
			)
	}

	//Filtering
	filterDistrictsBeforeLoad(id) {
		//e.preventDefault();

		//this.setState({loading : true});

		//axios.get(`/districts/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		axios.get(API_URL + `/districts/filter/${id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }).then(response => {
			this.setState({
				Districts: response.data,
				//loading : false,
			})
		}).catch(error => {
			if (error.response.status == '401') {
				swal({
					title: "",
					text: "Your session has expired. You'll automatically be logged out within seconds.",
					icon: "warning",
					timer: 2000
				})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			} else {
				swal({
					title: "",
					text: error.name + ': ' + error.message,
					icon: "error",
					button: true
				})
					.then((sucess) => {
						window.location.reload();
					});
			}
		})
	}

	filterDistrictsAfterLoad(e) {
		e.preventDefault();

		//this.setState({loading : true});

		//axios.get(`/districts/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		axios.get(API_URL + `/districts/filter/${e.target.value}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }).then(response => {
			this.setState({
				Districts: response.data,
				//loading : false,
			})
		}).catch(error => {
			if (error.response.status == '401') {
				swal({
					title: "",
					text: "Your session has expired. You'll automatically be logged out within seconds.",
					icon: "warning",
					timer: 2000
				})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			} else {
				swal({
					title: "",
					text: error.name + ': ' + error.message,
					icon: "error",
					button: true
				})
					.then((sucess) => {
						//window.location.reload();
					});
			}
		})
	}

	filterZonesBeforeLoad(id) {
		//e.preventDefault();

		//this.setState({loading : true});
		//axios.get(`/zones/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		axios.get(API_URL + `/zones/filter/${id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }).then(response => {
			this.setState({
				Zones: response.data,
				//loading : false,
			})
		}).catch(error => {
			if (error.response.status == '401') {
				swal({
					title: "",
					text: "Your session has expired. You'll automatically be logged out within seconds.",
					icon: "warning",
					timer: 2000
				})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			} else {
				swal({
					title: "",
					text: error.name + ': ' + error.message,
					icon: "error",
					button: true
				})
					.then((sucess) => {
						window.location.reload();
					});
			}
		})
	}

	filterZonesAfterLoad(e) {
		e.preventDefault();

		//this.setState({loading : true});
		//axios.get(`/zones/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		axios.get(API_URL + `/zones/filter/${e.target.value}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }).then(response => {
			this.setState({
				Zones: response.data,
				//loading : false,
			})
		}).catch(error => {
			if (error.response.status == '401') {
				swal({
					title: "",
					text: "Your session has expired. You'll automatically be logged out within seconds.",
					icon: "warning",
					timer: 2000
				})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			} else {
				swal({
					title: "",
					text: error.name + ': ' + error.message,
					icon: "error",
					button: true
				})
					.then((sucess) => {
						//window.location.reload();
					});
			}
		})
	}

	filterSchoolsBeforeLoad(id) {
		//e.preventDefault();

		//this.setState({loading : true});
		//axios.get(`/schools/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		axios.get(API_URL + `/schools/filter/${id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }).then(response => {
			this.setState({
				Schools: response.data,
				//loading : false,
			})
		}).catch(error => {
			if (error.response.status == '401') {
				swal({
					title: "",
					text: "Your session has expired. You'll automatically be logged out within seconds.",
					icon: "warning",
					timer: 2000
				})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			} else {
				swal({
					title: "",
					text: error.name + ': ' + error.message,
					icon: "error",
					button: true
				})
					.then((sucess) => {
						window.location.reload();
					});
			}
		})
	}

	filterSchoolsAfterLoad(e) {
		e.preventDefault();

		//this.setState({loading : true});
		//axios.get(`/schools/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		axios.get(API_URL + `/schools/filter/${e.target.value}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }).then(response => {
			this.setState({
				Schools: response.data,
				//loading : false,
			})
		}).catch(error => {
			if (error.response.status == '401') {
				swal({
					title: "",
					text: "Your session has expired. You'll automatically be logged out within seconds.",
					icon: "warning",
					timer: 2000
				})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			} else {
				swal({
					title: "",
					text: error.name + ': ' + error.message,
					icon: "error",
					button: true
				})
					.then((sucess) => {
						//window.location.reload();
					});
			}
		})
	}

	getRecentVisitBySchool(e) {
		e.preventDefault();

		//this.setState({loading : true});
		//axios.get(`/schools/filter/${e.target.value}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
		axios.get(API_URL + `/inspection_visits/recent/${e.target.value}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }).then(response => {
			this.setState({
				prev_visitation_date: (response.data['present_visitation_date'] == undefined) ? '00/00/0000' : response.data['present_visitation_date'],
				//loading : false,
			})
		}).catch(error => {
			if (error.response.status == '401') {
				swal({
					title: "",
					text: "Your session has expired. You'll automatically be logged out within seconds.",
					icon: "warning",
					timer: 2000
				})
					.then((sucess) => {
						window.sessionStorage.clear();
						window.location.reload();
					});
			} else {
				swal({
					title: "",
					text: error.name + ': ' + error.message,
					icon: "error",
					button: true
				})
					.then((sucess) => {
						//window.location.reload();
					});
			}
		})
	}

	//Edit user 
	editInspectionVisit(id) {

		this.setState({ loading: true });
		const visit = this.state.Visits.filter(v => v.visit_id === id)[0]
		this.filterDistrictsBeforeLoad(visit.division_id);
		this.filterZonesBeforeLoad(visit.district_id);
		this.filterSchoolsBeforeLoad(visit.zone_id);

		setTimeout(() => {
			axios.get(API_URL + `/inspection_visits/edit/${id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }).then(response => {
			
				this.setState({
					id: response.data[0].visit_id,
					emis_id: response.data[0].emis_id,
					head_teacher: response.data[0].head_teacher,
					phone_number: response.data[0].phone_number,
					present_visitation_date: response.data[0].present_visitation_date,
					prev_visitation_date: response.data[0].prev_visitation_date,
					lead_inspector_id: response.data[0].lead_inspector_id,
					first_inspector_id: response.data[0].first_inspector_id,
					second_inspector_id: response.data[0].second_inspector_id,
					third_inspector_id: response.data[0].third_inspector_id,
					fourth_inspector_id: response.data[0].fourth_inspector_id,
					school_address: response.data[0].school_address,
					establishment_year: response.data[0].establishment_year,
					establishment: response.data[0].establishment,
					enrolment_boys: response.data[0].enrolment_boys,
					enrolment_girls: response.data[0].enrolment_girls,
					attendance_boys: response.data[0].attendance_boys,
					attendance_girls: response.data[0].attendance_girls,
					number_of_teachers: response.data[0].number_of_teachers,
					unqualified_teachers: response.data[0].unqualified_teachers,
					action_plan_date: response.data[0].action_plan_date,
					next_inspection_date: response.data[0].next_inspection_date,
					zone_id: response.data[0].zone_id,
					sector_id: response.data[0].sector_id,
					district_id: response.data[0].district_id,
					division_id: response.data[0].division_id,
					lead_advisor_id: response.data[0].lead_advisor_id,
					govt_chair_id: response.data[0].govt_chair_id,
					visit_type_id: response.data[0].visit_type_id,
					EditModalVisible: true,
					modalVisible: true,
					loading: false,
					EmisIdState: 'has-success',
					ZoneIdState: 'has-success',
					DistrictIdState: 'has-success',
					DivisionIdState: 'has-success',
					HeadTeacherIdState: 'has-success',
					PhoneState: 'has-success',
					presentDateState: 'has-success',
					prevDateState: 'has-success',
					leadInspectorIdState: 'has-success',
					firstInspectorIdState: 'has-success',
					secondInspectorIdState: 'has-success',
					thirdInspectorIdState: 'has-success',
					fourthInspectorIdState: 'has-success',
					SchoolAddressState: 'has-success',
					EstYearState: 'has-success',
					EstState: 'has-success',
					EnrolledBoysState: 'has-success',
					EnrolledGirlsState: 'has-success',
					AttendanceBoysState: 'has-success',
					AttendanceGirlsState: 'has-success',
					TotalTeachersState: 'has-success',
					UnqualifiedTeachersState: 'has-success',
					pubDateState: 'has-success',
					actionDateState: 'has-success',
					nextInspectionDateState: 'has-success',
					leadAdvisorIdState: 'has-success',
					govChairIdState: 'has-success',
					visitTypeIdState: 'has-success',
					completed: {},
					activeStep: 0
				})
	
	
			}).catch(error => {
				if (error.response.status == '401') {
					swal({
						title: "",
						text: "Your session has expired. You'll automatically be logged out within seconds.",
						icon: "warning",
						timer: 2000
					})
						.then((sucess) => {
							window.sessionStorage.clear();
							window.location.reload();
						});
				} else {
					swal({
						title: "",
						text: error.name + ': ' + error.message,
						icon: "error",
						button: true
					})
						.then((sucess) => {
							//window.location.reload();
						});
				}
			})
		}, 3000);
		//axios.get(API_URL + `/api/users/edit/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then(response => {
	}

	//Update 
	updateInspectionVisit(event) {

		if(event) event.preventDefault()
		this.setState({
			loading: true
		})
		const { history } = this.props
		const id = this.state.id

		const inspection = {
			id: this.state.visit_id,
			emis_id: this.state.emis_id,
			head_teacher: this.state.head_teacher,
			phone_number: this.state.phone_number,
			present_visitation_date: this.state.present_visitation_date,
			prev_visitation_date: this.state.prev_visitation_date,
			lead_inspector_id: this.state.lead_inspector_id,
			first_inspector_id: this.state.first_inspector_id,
			second_inspector_id: this.state.second_inspector_id,
			third_inspector_id: this.state.third_inspector_id,
			fourth_inspector_id: this.state.fourth_inspector_id,
			school_address: this.state.school_address,
			establishment_year: this.state.establishment_year,
			enrolment_boys: this.state.enrolment_boys,
			enrolment_girls: this.state.enrolment_girls,
			attendance_boys: this.state.attendance_boys,
			attendance_girls: this.state.attendance_girls,
			number_of_teachers: this.state.number_of_teachers,
			unqualified_teachers: this.state.unqualified_teachers,
			action_plan_date: this.state.action_plan_date,
			next_inspection_date: this.state.next_inspection_date,
			zone_id: this.state.zone_id,
			sector_id: this.state.sector_id,
			district_id: this.state.district_id,
			division_id: this.state.division_id,
			lead_advisor_id: this.state.lead_advisor_id,
			govt_chair_id: this.state.govt_chair_id,
			visit_type_id: this.state.visit_type_id,

		}
		axios.patch(API_URL + `/inspection_visits/update/${id}`, inspection, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
			.then(response => {
				//Conditional based on backed responses . Introduced for error handling via message passing with REST
				if (response.data['status'] == 'success') {
					swal({
						title: "",
						text: response.data['message'],
						icon: response.data['status'],
						timer: 2500,
						buttons: false
					});
					this.setState({
						...initialState, bearer_auth: window.sessionStorage.getItem('token'),
						logged_user_id: window.sessionStorage.getItem('uid'),
						sector_id: window.sessionStorage.getItem("sector"),
						present_visitation_date: moment().format("YYYY-MM-DD"),
						role: window.sessionStorage.getItem("urole")
})
					this.setupData()
				}
			})
			.catch(error => {
				this.setState({
					...initialState, bearer_auth: window.sessionStorage.getItem('token'),
					logged_user_id: window.sessionStorage.getItem('uid'),
					sector_id: window.sessionStorage.getItem("sector"),
					present_visitation_date: moment().format("YYYY-MM-DD"),
					role: window.sessionStorage.getItem("urole")
})
				this.setupData()
				swal({
					title: "",
					text: error.name + ': ' + error.message,
					icon: "error",
					button: true
				})
			})
	}

	//delete
	deleteInspectionVisit(id) {
		//Confirming deletion
		swal({
			title: "",
			text: "Are you sure to delete the inspection visit?",
			icon: "warning",
			dangerMode: true,
			buttons: {
				confirm: {
					text: "Delete",
					value: true,
					visible: true,
					className: "",
					closeModal: true
				},
				cancel: {
					text: "Cancel",
					value: false,
					visible: true,
					className: "",
					closeModal: true,
				}
			}
		})
			.then((willDelete) => {
				//Confirmed condition
				if (willDelete) {

					//this.setState({loading : true});
					//axios.delete(API_URL + `/api/users/delete/${id}`,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}}).then((response) => {
					axios.delete(API_URL + `/inspection_visits/delete/${id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }).then((response) => {
						//this.setState({loading: false});

						swal({
							title: "",
							text: "School visit deleted Successfully!",
							icon: "success",
							timer: 2000,
							buttons: false

						});
						axios.get(API_URL + `/inspection_visits`,
							{ headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }
						).then(
							(visit) => {
								this.setState({
									Visits: visit.data
								})
							}
						)

					}).catch(error => {
						if (error.response.status == '401') {
							swal({
								title: "",
								text: "Your session has expired. You'll automatically be logged out within seconds.",
								icon: "warning",
								timer: 2000
							})
								.then((sucess) => {
									window.sessionStorage.clear();
									window.location.reload();
								});
						} else {
							swal({
								title: "",
								text: error.name + ': ' + error.message,
								icon: "error",
								button: true
							})
								.then((sucess) => {
									//window.location.reload();
								});
						}
					})
					//unconfirmed condition
				} else {
					//window.location.reload();
				}
			});
	}

	getYears() {
		for (let i = new Date().getFullYear(); i >= 1500; i--) {
			this.state.years.push(i);
		}
	}

	setupData() {
		this.setState({ loading: true });
		if (this.state.role == 'Division Manager') {
			const div_id = window.sessionStorage.getItem('division');

			Promise.all([axios.get(API_URL + `/inspection_visits/division/${div_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/divisions`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/sectors`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/visit_types`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/users`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/divisions/${div_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
				([visit, division, sector, visit_type, user, div_data]) => {
					this.setState({
						Visits: visit.data,
						Divisions: division.data,
						Sectors: sector.data,
						VisitTypes: visit_type.data,
						Users: user.data,
						juridistiction: (div_data.data.length >= 1) ? div_data.data[0]['division_name'] + " DIVISION'S INSPECTION VISITS" : 'NULL DATA ON',
						loading: false,
					});


				}
			).catch(error => {
				this.setState({ loading: false });
				if (error.response.status == '401') {
					swal({
						title: "",
						text: "Your session has expired. You'll automatically be logged out within seconds.",
						icon: "warning",
						timer: 2000
					})
						.then((sucess) => {
							// window.sessionStorage.clear();
							// window.location.reload();
						});
				} else {
					swal({
						title: "",
						text: error.name + ': ' + error.message,
						icon: "error",
						button: true
					})
						.then((sucess) => {
							// window.location.reload();
						});
				}
			})

		} else if (this.state.role == 'DEM') {
			const dist_id = window.sessionStorage.getItem('district');

			Promise.all([axios.get(API_URL + `/inspection_visits/district/${dist_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/divisions`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/sectors`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/visit_types`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/users`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/districts/${dist_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
				([visit, division, sector, visit_type, user, district_data]) => {
					this.setState({
						Visits: visit.data,
						Divisions: division.data,
						Sectors: sector.data,
						VisitTypes: visit_type.data,
						Users: user.data,
						juridistiction: (district_data.data.length >= 1) ? district_data.data[0]['district_name'] + " DISTRICT'S INSPECTION VISITS" : 'NULL DATA ON',
						loading: false,
					})
				}
			).catch(error => {
				this.setState({ loading: false });
				if (error.response.status == '401') {
					swal({
						title: "",
						text: "Your session has expired. You'll automatically be logged out within seconds.",
						icon: "warning",
						timer: 2000
					})
						.then((sucess) => {
							// window.sessionStorage.clear();
							// window.location.reload();
						});
				} else {
					swal({
						title: "",
						text: error.name + ': ' + error.message,
						icon: "error",
						button: true
					})
						.then((sucess) => {
							// window.location.reload();
						});
				}
			})

		} else if (this.state.role == 'Secondary Advisor') {
			const zon_id = window.sessionStorage.getItem('zone');

			Promise.all([axios.get(API_URL + `/inspection_visits/zone/${zon_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/divisions`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/sectors`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/visit_types`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/users`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/zones/${zon_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
				([visit, division, sector, visit_type, user, zone_data]) => {
					this.setState({
						Visits: visit.data,
						Divisions: division.data,
						Sectors: sector.data,
						VisitTypes: visit_type.data,
						Users: user.data,
						juridistiction: (zone_data.data.length >= 1) ? zone_data.data[0]['zone_name'] + " CLUSTER'S INSPECTION VISITS" : 'NULL DATA ON',
						loading: false,
					})
				}
			).catch(error => {
				this.setState({ loading: false });

				if (error.response.status == '401') {
					swal({
						title: "",
						text: "Your session has expired. You'll automatically be logged out within seconds.",
						icon: "warning",
						timer: 2000
					})
						.then((sucess) => {
							// window.sessionStorage.clear();
							// window.location.reload();
						});
				} else {
					swal({
						title: "",
						text: error.name + ': ' + error.message,
						icon: "error",
						button: true
					})
						.then((sucess) => {
							// window.location.reload();
						});
				}
			})

		} else if (this.state.role == 'PEA') {
			const zon_id = window.sessionStorage.getItem('zone');

			Promise.all([axios.get(API_URL + `/inspection_visits/zone/${zon_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/divisions`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/sectors`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/visit_types`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/users`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/zones/${zon_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
				([visit, division, sector, visit_type, user, zone_data]) => {
					this.setState({
						Visits: visit.data,
						Divisions: division.data,
						Sectors: sector.data,
						VisitTypes: visit_type.data,
						Users: user.data,
						juridistiction: (zone_data.data.length >= 1) ? zone_data.data[0]['zone_name'] + " ZONE'S INSPECTION VISITS" : 'NULL DATA ON',
						loading: false,
					})
				}
			).catch(error => {
				this.setState({ loading: false });

				if (error.response.status == '401') {
					swal({
						title: "",
						text: "Your session has expired. You'll automatically be logged out within seconds.",
						icon: "warning",
						timer: 2000
					})
						.then((sucess) => {
							// window.sessionStorage.clear();
							// window.location.reload();
						});
				} else {
					swal({
						title: "",
						text: error.name + ': ' + error.message,
						icon: "error",
						button: true
					})
						.then((sucess) => {
							// window.location.reload();
						});
				}
			})

		} else if (this.state.role == 'Head Teacher') {
			const sch_id = window.sessionStorage.getItem('emis');

			Promise.all([axios.get(API_URL + `/inspection_visits/school/${sch_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/divisions`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/sectors`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/visit_types`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/users`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/schools/${sch_id}`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
				([visit, division, sector, visit_type, user, school_data]) => {
					this.setState({
						Visits: visit.data,
						Divisions: division.data,
						Sectors: sector.data,
						VisitTypes: visit_type.data,
						Users: user.data,
						juridistiction: (school_data.data.length >= 1) ? school_data.data[0]['school_name'] + " SCHOOL'S INSPECTION VISITS" : 'NULL DATA ON',
						loading: false,
					})
				}
			).catch(error => {
				this.setState({ loading: false });

				if (error.response.status == '401') {
					swal({
						title: "",
						text: "Your session has expired. You'll automatically be logged out within seconds.",
						icon: "warning",
						timer: 2000
					})
						.then((sucess) => {
							// window.sessionStorage.clear();
							// window.location.reload();
						});
				} else {
					swal({
						title: "",
						text: error.name + ': ' + error.message,
						icon: "error",
						button: true
					})
						.then((sucess) => {
							// window.location.reload();
						});
				}
			})

		} else {
			Promise.all([axios.get(API_URL + `/inspection_visits`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/divisions`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/sectors`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/visit_types`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } }), axios.get(API_URL + `/users`, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })]).then(
				([visit, division, sector, visit_type, user]) => {
					this.setState({
						Visits: visit.data,
						Divisions: division.data,
						Sectors: sector.data,
						VisitTypes: visit_type.data,
						Users: user.data,
						juridistiction: "NATIONAL INSPECTION VISITS",
						loading: false,
					})
				}
			).catch(error => {
				this.setState({ loading: false });

				if (error.response.status == '401') {
					swal({
						title: "",
						text: "Your session has expired. You'll automatically be logged out within seconds.",
						icon: "warning",
						timer: 2000
					})
						.then((sucess) => {
							// window.sessionStorage.clear();
							// window.location.reload();
						});
				} else {
					swal({
						title: "",
						text: error.name + ': ' + error.message,
						icon: "error",
						button: true
					})
						.then((sucess) => {
							// window.location.reload();
						});
				}
			})
		}

		this.getYears();
	}


	componentDidMount() {
		this.setupData()
	}

	//Add user
	addInspectionVisit() {
		if (this.state.EditModalVisible === true) return this.updateInspectionVisit()
		const { history } = this.props
		console.log("adding...")
		var invalid = false
		for (var key of Object.keys(this.state).filter(e => !['AttendanceBoysState', 'AttendanceGirlsState'].includes(e))) {
			if (this.state[key] === 'has-danger') {
				invalid = true
				return swal({
					title: '',
					text: "Please make sure all fields are valid",
					icon: "error"
				})
			}
		}

		if (invalid) return;

		this.setState({ loading: true })

		const inspection = {
			emis_id: this.state.emis_id,
			head_teacher: this.state.head_teacher,
			phone_number: '+265' + this.state.phone_number,
			present_visitation_date: this.state.present_visitation_date,
			prev_visitation_date: this.state.prev_visitation_date,
			lead_inspector_id: this.state.lead_inspector_id,
			first_inspector_id: this.state.first_inspector_id,
			second_inspector_id: this.state.second_inspector_id,
			third_inspector_id: this.state.third_inspector_id,
			fourth_inspector_id: this.state.fourth_inspector_id,
			school_address: this.state.school_address,
			establishment_year: this.state.establishment_year,
			establishment: this.state.establishment,
			enrolment_boys: this.state.enrolment_boys,
			enrolment_girls: this.state.enrolment_girls,
			attendance_boys: this.state.attendance_boys,
			attendance_girls: this.state.attendance_girls,
			number_of_teachers: this.state.number_of_teachers,
			unqualified_teachers: this.state.unqualified_teachers,
			action_plan_date: moment(this.state.present_visitation_date).add(2, 'weeks').format("YYYY-MM-DD"),
			next_inspection_date: moment(this.state.present_visitation_date).add(6, 'months').format("YYYY-MM-DD"),
			zone_id: this.state.zone_id,
			sector_id: this.state.sector_id,
			district_id: this.state.district_id,
			division_id: this.state.division_id,
			lead_advisor_id: this.state.lead_advisor_id,
			govt_chair_id: this.state.govt_chair_id,
			visit_type_id: this.state.visit_type_id,

			attendanceError: '',
			attendanceGirlsError: '',


		}

		
		// console.log(user);
		//console.log(exam);
		//axios.post(API_URL + '/api/users', user,{ headers: {"Authorization" : `Bearer ${this.state.bearer_auth}`}})
		axios.post(API_URL + '/inspection_visits', inspection, { headers: { "Authorization": `Bearer ${this.state.bearer_auth}` } })
			.then(response => {
				this.setState({ loading: false });
				if (response.status == 406) {
					return swal({
						title: "Could not save visit",
						text: 'visit name with the same date already exists',
						icon: 'danger',
						button: true
					})
				}
				//Conditional based on backed responses . Introduced for error handling via message passing with REST
				if (response.data['status'] == 'success') {
					this.setState({
						...initialState,
						bearer_auth: window.sessionStorage.getItem('token'),
						logged_user_id: window.sessionStorage.getItem('uid'),
						sector_id: window.sessionStorage.getItem("sector"),
						present_visitation_date: moment().format("YYYY-MM-DD"),
						role: window.sessionStorage.getItem("urole")
					});
					this.setupData();
					swal({
						title: "",
						text: 'Visit has been created',
						icon: 'success',
						timer: 2500,
						buttons: false
					})
					
				} else {
					this.setState({
						modalVisible: true,
					});
					if (response.status == 406) {
						return swal({
							title: "Could not save visit",
							text: 'visit name with the same date already exists',
							icon: 'danger',
							button: true
						})
					}
					swal({
						title: "Could not save visit",
						text: 'an error occurred while saving visit. make sure all fields are populated accordingly',
						icon: 'danger',
						button: true
					})
						.then((sucess) => {
							// window.location.reload();
						});
				}

			})
			.catch(error => {
				this.setState({
					modalVisible: true,
					loading: false
				});
				if (error.response.status == 406) {
					return swal({
						title: "Could not save visit",
						text: 'visit name with the same date already exists',
						icon: 'danger',
						button: true
					})
				}
				swal({
					title: "Could not save visit",
					text: 'an error occurred while saving visit. make sure all fields are populated accordingly',
					icon: 'danger',
					button: true
				}).then((sucess) => {
					//window.location.reload();
				});
			})
	}


	//Form inputs handler and validator
	handleInput(e) {
		e.preventDefault();
		if (e.target.name === 'expiry_date') {
			if (e.target.value != '') {
				this.setState({ ExpiryVisitDate: 'has-success', [e.target.id]: e.target.value });
				const currentExpiryDate = new Date();
				const newExpiryDate = new Date(e.target.value)
				if (newExpiryDate < currentExpiryDate) {
					this.setState({ ExpiryVisitDate: 'has-danger', expiryDateMessage: 'Expiry date cannot be set to a past date' });
				}
			} else {
				this.setState({ presentDateState: 'has-danger' });
			}
		}
		if (e.target.name === 'present_visitation_date') {
			if (e.target.value != '') {
				if (moment(e.target.value).isAfter(moment())) {
					return this.setState({ presentDateState: 'has-danger' });
				}
				this.setState({ presentDateState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ presentDateState: 'has-danger' });
			}
		} else if (e.target.name === 'establishment') {
			if (e.target.value != '') {
				this.setState({ EstState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ EstState: 'has-danger' });
			}

		}
		else if (e.target.name === 'prev_visitation_date') {
			if (e.target.value != '' && e.target.value < this.state.present_visitation_date) {
				this.setState({ prevDateState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ prevDateState: 'has-danger' });
			}

		} else if (e.target.name === 'lead_inspector_id') {
			if (e.target.value != '') {
				this.setState({ leadInspectorIdState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ leadInspectorIdState: 'has-danger' });
			}

		} else if (e.target.name === 'first_inspector_id') {
			if (e.target.value != '') {
				this.setState({ firstInspectorIdState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ firstInspectorIdState: 'has-danger' });
			}

		} else if (e.target.name === 'second_inspector_id') {
			if (e.target.value != '') {
				this.setState({ secondInspectorIdState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ secondInspectorIdState: 'has-danger' });
			}

		} else if (e.target.name === 'third_inspector_id') {
			this.setState({ thirdInspectorIdState: 'has-success', [e.target.id]: e.target.value });

		} else if (e.target.name === 'fourth_inspector_id') {
			this.setState({ fourthInspectorIdState: 'has-success', [e.target.id]: e.target.value });
		}

		else if (e.target.name === 'action_plan_date') {
			if (e.target.value != '' && e.target.value > this.state.present_visitation_date) {
				this.setState({ actionDateState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ actionDateState: 'has-danger' });
			}

		} else if (e.target.name === 'next_inspection_date') {
			if (e.target.value != '' && e.target.value > this.state.action_plan_date) {
				this.setState({ nextInspectionDateState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ nextInspectionDateState: 'has-danger' });
			}

		} else if (e.target.name === 'lead_advisor_id') {
			if (e.target.value != '' && e.target.value != this.state.govt_chair_id) {
				this.setState({ leadAdvisorIdState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ leadAdvisorIdState: 'has-danger' });
			}

		} else if (e.target.name === 'govt_chair_id') {
			if (e.target.value != '' && e.target.value != this.state.lead_advisor_id) {
				this.setState({ govChairIdState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ govChairIdState: 'has-danger' });
			}

		} else if (e.target.name === 'visit_type_id') {
			if (e.target.value != '') {
				this.setState({ visitTypeIdState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ visitTypeIdState: 'has-danger' });
			}

		} else if (e.target.name === 'emis_id') {
			if (e.target.value != '') {
				this.setState({ EmisIdState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ EmisIdState: 'has-danger' });
			}

		} else if (e.target.name === 'zone_id') {
			if (e.target.value != '') {
				this.setState({ ZoneIdState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ ZoneIdState: 'has-danger' });
			}

		} else if (e.target.name === 'district_id') {
			if (e.target.value != '') {
				this.setState({ DistrictIdState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ DistrictIdState: 'has-danger' });
			}

		} else if (e.target.name === 'division_id') {
			if (e.target.value != '') {
				this.setState({ DivisionIdState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ DivisionIdState: 'has-danger' });
			}

		} else if (e.target.name === 'sector_id') {
			if (e.target.value != '') {
				this.setState({ SectorIdState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ SectorIdState: 'has-danger' });
			}

		} else if (e.target.name === 'phone_number') {
			if (e.target.value != '') {
				this.setState({ PhoneState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ PhoneState: 'has-danger' });
			}

		} else if (e.target.name === 'head_teacher') {
			if (e.target.value != '') {
				this.setState({ HeadTeacherIdState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ HeadTeacherIdState: 'has-danger' });
			}

		} else if (e.target.name === 'head_teacher_email') {
			if (e.target.value != '') {
				this.setState({ HeadTeacherEmailState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ HeadTeacherEmailState: 'has-danger' });
			}

		}else if (e.target.name === 'school_address') {
			if (e.target.value != '') {
				this.setState({ SchoolAddressState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ SchoolAddressState: 'has-danger' });
			}

		} else if (e.target.name === 'establishment_year') {
			if (e.target.value != '') {
				this.setState({ EstYearState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ EstYearState: 'has-danger' });
			}

		} else if (e.target.name === 'enrolment_boys') {
			if (e.target.value != '') {
				this.setState({ EnrolledBoysState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ EnrolledBoysState: 'has-danger' });
			}

		} else if (e.target.name === 'enrolment_girls') {
			if (e.target.value != '') {
				this.setState({ EnrolledGirlsState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ EnrolledGirlsState: 'has-danger' });
			}

		} else if (e.target.name === 'attendance_boys') {

			if (e.target.value != '') {
				var enrolled = parseInt(this.state.enrolment_boys)
				var value = parseInt(e.target.value)
				if (enrolled < value) {
					this.setState({ AttendanceBoysState: 'has-success', [e.target.id]: e.target.value, attendanceError: '' });
					return this.setState({ AttendanceBoysState: 'has-danger', attendanceError: 'Attendance can not be more than enrolled students' });
				}
				this.setState({ AttendanceBoysState: 'has-success', [e.target.id]: e.target.value, attendanceError: '' });
			} else {
				this.setState({ AttendanceBoysState: 'has-danger', attendanceError: 'this field is required' });
			}

		} else if (e.target.name === 'attendance_girls') {
			if (e.target.value != '') {
				var enrolled = parseInt(this.state.enrolment_girls)
				var value = parseInt(e.target.value)
				if (enrolled < value) {
					this.setState({ AttendanceGirlsState: 'has-success', [e.target.id]: e.target.value, attendanceGirlsError: '' });
					return this.setState({ AttendanceGirlsState: 'has-danger', attendanceGirlsError: 'Attendance can not be more than enrolled students' });
				}
				this.setState({ AttendanceGirlsState: 'has-success', [e.target.id]: e.target.value, attendanceGirlsError: '' });
			} else {
				this.setState({ AttendanceGirlsState: 'has-danger', attendanceGirlsError: 'field is required' });
			}

		} else if (e.target.name === 'number_of_teachers') {
			if (e.target.value != '') {
				this.setState({ TotalTeachersState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ TotalTeachersState: 'has-danger' });
			}

		} else if (e.target.name === 'unqualified_teachers') {
			if (e.target.value != '') {
				this.setState({ UnqualifiedTeachersState: 'has-success', [e.target.id]: e.target.value });
			} else {
				this.setState({ UnqualifiedTeachersState: 'has-danger' });
			}

		}

	}

	render() {
		const { Visits, Divisions, Sectors, VisitTypes, Users } = this.state;

		const steps = ['School', 'School Details', 'Inspector Details'];

		const totalSteps = () => {
			return steps.length;
		};

		const completedSteps = () => {
			return Object.keys(this.state.completed).length;
		};

		const isLastStep = () => {
			return this.state.activeStep === totalSteps() - 1;
		};

		const allStepsCompleted = () => {
			return completedSteps() === totalSteps();
		};

		const showAlert = (message = undefined) => {
			return swal({
				title: '',
				text: message ? message : "Please make sure all fields are valid",
				icon: "error"
			})
		}

		const handleNext = () => {
			if (isLastStep() && allStepsCompleted()) {
				return this.addInspectionVisit()
			}
			const newActiveStep =
				isLastStep() && !allStepsCompleted()
					? // It's the last step, but not all steps have been completed,
					// find the first step that has been completed
					0 : this.state.activeStep + 1;
			this.setState({
				activeStep: newActiveStep
			})
		};

		const handleBack = () => {
			const newCompleted = this.state.completed
			delete newCompleted[this.state.activeStep-1]
			this.setState({
				completed: newCompleted,
				activeStep: this.state.activeStep - 1
			})
		};

		const handleStep = (step) => () => {
			this.setState({
				activeStep: step
			})
		};

		const handleComplete = () => {
			if (this.state.activeStep == 0) {
				if ([undefined, 'has-danger'].includes(this.state.DivisionIdState)) {
					this.setState({ DivisionIdState: 'has-danger' });
					return showAlert()
				}
				if ([undefined, 'has-danger'].includes(this.state.DistrictIdState)) {
					this.setState({ DistrictIdState: 'has-danger' });
					return showAlert()
				}
				if ([undefined, 'has-danger'].includes(this.state.ZoneIdState)) {
					this.setState({ ZoneIdState: 'has-danger' });
					return showAlert()
				}
				if ([undefined, 'has-danger'].includes(this.state.EmisIdState)) {
					this.setState({ EmisIdState: 'has-danger' });
					return showAlert()
				}
			}
			if (this.state.activeStep == 1) {
				if ([undefined, 'has-danger'].includes(this.state.PhoneState)) {
					this.setState({ PhoneState: 'has-danger' });
					return showAlert()
				}
				if ([undefined, 'has-danger'].includes(this.state.PhoneState)) {
					this.setState({ PhoneState: 'has-danger' });
					return showAlert()
				}
				if ([undefined, 'has-danger'].includes(this.state.SchoolAddressState)) {
					this.setState({ SchoolAddressState: 'has-danger' });
					return showAlert()
				}
				if ([undefined, 'has-danger'].includes(this.state.EstYearState)) {
					this.setState({ EstYearState: 'has-danger' });
					return showAlert()
				}
				if ([undefined, 'has-danger'].includes(this.state.EstState)) {
					this.setState({ EstState: 'has-danger' });
					return showAlert()
				}
				if ([undefined, 'has-danger'].includes(this.state.EnrolledBoysState)) {
					this.setState({ EnrolledBoysState: 'has-danger' });
					return showAlert()
				}
				if ([undefined, 'has-danger'].includes(this.state.EnrolledGirlsState)) {
					this.setState({ EnrolledGirlsState: 'has-danger' });
					return showAlert()
				}
				if ([undefined, 'has-danger'].includes(this.state.TotalTeachersState)) {
					this.setState({ TotalTeachersState: 'has-danger' });
					return showAlert()
				}
				if ([undefined, 'has-danger'].includes(this.state.UnqualifiedTeachersState)) {
					this.setState({ UnqualifiedTeachersState: 'has-danger' });
					return showAlert()
				}
			}
			if (this.state.activeStep == 2) {

				const ins = [this.state.lead_inspector_id, this.state.first_inspector_id, this.state.second_inspector_id, this.state.third_inspector_id, this.state.fourth_inspector_id]
				if (!ins.includes(sessionStorage.getItem("uid"))) {
					return showAlert("Current user must be present in the Inspection Team")
				}
				if ([undefined, 'has-danger'].includes(this.state.leadInspectorIdState)) {
					this.setState({ leadInspectorIdState: 'has-danger' });
					return showAlert()
				}
				if ([undefined, 'has-danger'].includes(this.state.secondInspectorIdState)) {
					this.setState({ secondInspectorIdState: 'has-danger' });
					return showAlert()
				}
				if ([undefined, 'has-danger'].includes(this.state.leadAdvisorIdState)) {
					this.setState({ leadAdvisorIdState: 'has-danger' });
					return showAlert()
				}
				if ([undefined, 'has-danger'].includes(this.state.govChairIdState)) {
					this.setState({ govChairIdState: 'has-danger' });
					return showAlert()
				}

			}
			const newCompleted = this.state.completed;
			newCompleted[this.state.activeStep] = true;
			this.setState({
				completed: newCompleted
			})
			handleNext();
		};

		const handleReset = () => {
			this.setState({
				completed: {},
				activeStep: 0
			})
		};

		const data = {
			columns: [
				/* { 
				  title: 'Visit Id',
				   field: 'visit_id' 
				}, */
				{
					title: 'School',
					field: 'emis_id',
					hidden: false,
				},
				{
					title: 'Date',
					field: 'present_visitation_date',
					type: 'date',
					hidden: false,
				},
				{
					title: 'Head Teacher',
					field: 'head_teacher',
					hidden: true
				},
				{
					title: 'Lead Inspector',
					field: 'lead_inspector_id',
					hidden: true,
				},
				{
					title: 'Advisor',
					field: 'pea',
					hidden: true,
				},
				{
					title: this.state.sector_id == 1 ? 'Zone' : 'Cluster',
					field: 'cluster_id',
					hidden: true,
				},
				{
					title: 'District',
					field: 'district_id',
					hidden: true,
				},
				{
					title: 'Division',
					field: 'division_id',
					hidden: true,
				},
				{
					title: 'Expiry Date',
					field: 'expiry_date',
					hidden: sessionStorage.getItem('urole') == 'System Administrator' ? false : true,
				},
				{
					title: 'Actions',
					field: 'OPTIONS',
					export: false,
				},
			],

		};

		return (
			<React.Fragment>
				<div className="container-fluid">
					<Row className="position-center">
						{(this.state.loading == true) ? <Loader /> : null}
					</Row>

					<Row className="align-items-center">
						<Col sm={6}>
							<div className="page-title-box">
								<ol className="breadcrumb mb-0">
									<li className="breadcrumb-item">
										<Link to="/dashboard">Home</Link>
									</li>

									<li className="breadcrumb-item active">inspection visit</li>
								</ol>
							</div>
						</Col>

						<Col sm="6">
							<div className="float-right d-none d-md-block">

							</div>
						</Col>
					</Row>

					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-body">
									<h3 className="card-title">School Inspection's Management</h3>
									<hr />
									<Button hidden={(this.state.role != 'Inspector') && (true)} color="success" onClick={this.toggle}> {<Add />} New Inspection Visit</Button>
									<hr />

									{
										//Add user modal

										/*
											NOTE the usage of the onChange and onBlur event to call the same function.
											The onBlur event has been used to enforce validation once the user move out of the field.
										*/
									}
									<Modal size="lg" isOpen={this.state.modalVisible}>
										<Form onSubmit={this.addInspectionVisit} >
											<ModalHeader toggle={this.toggle}>
												<center className="justify-content-center">INSPECTION VISIT ADDITION FORM</center>
											</ModalHeader>
											<ModalBody>
												<Box sx={{ width: '100%' }}>
													<Stepper nonLinear activeStep={this.state.activeStep}>
														{steps.map((label, index) => (
															<Step key={label} completed={this.state.completed[index]}>
																<StepButton color="inherit">
																	{label}
																</StepButton>
															</Step>
														))}
													</Stepper>
												</Box>
												<div style={{ marginTop: '3em' }}>
													{this.state.activeStep == 0 &&
														<React.Fragment>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="division">Division Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input type="select" name="division_id" id="division_id" defaultValue={this.state.division_id} invalid={this.state.DivisionIdState === 'has-danger'} onChange={(e) => { this.handleInput(e); this.filterDistrictsAfterLoad(e); }} onBlur={this.handleInput} required={true}>
																			<option></option>
																			{
																				Divisions.map(division => (
																					<option value={division.division_id} key={division.division_id}>{division.division_name}</option>
																				)
																				)
																			}
																		</Input>
																		<FormFeedback invalid>
																			The field is required.
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="district">District Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input type="select" name="district_id" id="district_id" defaultValue={this.state.district_id} invalid={this.state.DistrictIdState === 'has-danger'} onChange={(e) => { this.handleInput(e); this.filterZonesAfterLoad(e); }} onBlur={this.handleInput} required={true}>
																			<option></option>
																			{

																				this.state.Districts.map(district => (
																					<option value={district.district_id} key={district.district_id}>{district.district_name}</option>
																				)
																				)

																			}
																		</Input>
																		<FormFeedback invalid>
																			The field is required.
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="Zone">{this.state.sector_id == 1 ? 'Zone Name' : this.state.sector_id == 2 ? 'Cluster Name' : 'Zone/Cluster Name'}&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input type="select" name="zone_id" id="zone_id" defaultValue={this.state.zone_id} invalid={this.state.ZoneIdState === 'has-danger'} onChange={(e) => { this.handleInput(e); this.filterSchoolsAfterLoad(e); }} onBlur={this.handleInput} required={true}>
																			<option></option>
																			{
																				this.state.Zones.map(zone => (
																					//Logic circuit and
																					(this.state.sector_id == zone.sector_id) && <option value={zone.zone_id} key={zone.zone_id}>{zone.zone_name}</option>
																				)
																				)

																			}
																		</Input>
																		<FormFeedback invalid>
																			The field is required.
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="School">School Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input type="select" name="emis_id" id="emis_id" defaultValue={this.state.emis_id} invalid={this.state.EmisIdState === 'has-danger'} onChange={(e) => { this.handleInput(e); this.getRecentVisitBySchool(e); }} onBlur={(e) => { this.handleInput(e); }} required={true}>
																			<option></option>
																			{
																				this.state.Schools.map(school => (
																					<option value={school.emis_id} key={school.emis_id}>{school.school_name}</option>
																				)
																				)

																			}
																		</Input>
																		<FormFeedback invalid>
																			The field is required.
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>
														</React.Fragment>
													}
												</div>
												<div style={{ marginTop: '3em' }}>
													{this.state.activeStep == 1 &&
														<React.Fragment>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="head_teacher">Head Teacher&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input type="text" name="head_teacher" id="head_teacher" defaultValue={this.state.head_teacher} invalid={this.state.HeadTeacherIdState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true}>
																		</Input>
																		<FormFeedback invalid>
																			The field is required.
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="phone_number">Head Teacher's Phone&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<InputGroup>
																			<InputGroupText>
																				+265
																			</InputGroupText>
																			<Input type="number" name="phone_number" id="phone_number" defaultValue={this.state.phone_number} invalid={this.state.PhoneState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true}>
																			</Input>
																			<FormFeedback invalid>
																				The field is required and should be a valid phone number.
																			</FormFeedback>
																			</InputGroup>
																	</Col>
																</Row>
															</FormGroup>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="school_address">Postal Address&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input type="textarea" defaultValue={this.state.school_address} name="school_address" id="school_address" invalid={this.state.SchoolAddressState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true}>

																		</Input>

																		<FormFeedback invalid>
																			The field is required.
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="establishment_year">Year Of Establishment&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input type="select" defaultValue={this.state.establishment_year} name="establishment_year" id="establishment_year" invalid={this.state.EstYearState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true}>
																			<option></option>
																			{

																				this.state.years.map(year => (
																					<option value={year} key={year}>{year}</option>
																				)
																				)

																			}
																		</Input>

																		<FormFeedback invalid>
																			The field is required.
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="establishment">Establishment&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input type="select" defaultValue={this.state.establishment} name="establishment" id="establishment" invalid={this.state.EstState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true}>
																			<option></option>
																			{
																				sessionStorage.getItem('sector') == 2 ?

																				["Form 1-4", "Form 1-2", "Form 3-4"].map(est => (
																					<option value={est} key={est}>{est}</option>
																					)
																				)
																				:
																				["Full Primary", "LEA"].map(est => (
																					<option value={est} key={est}>{est}</option>
																					)
																				)
																			}
																		</Input>

																		<FormFeedback invalid>
																			The field is required.
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="number_of_teachers">Number of Qualified Teachers&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input min="0" type="number" defaultValue={this.state.number_of_teachers} name="number_of_teachers" id="number_of_teachers" invalid={this.state.TotalTeachersState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >

																		</Input>

																		<FormFeedback invalid>
																			The field is required.
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="unqualified_teachers">Number of Un-Qualified Teachers&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input min="0" type="number" defaultValue={this.state.unqualified_teachers} name="unqualified_teachers" id="unqualified_teachers" invalid={this.state.UnqualifiedTeachersState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true}>

																		</Input>

																		<FormFeedback invalid>
																			The field is required.
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="enrolment_boys">Total Enrolled Boys&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input min="0" type="number" defaultValue={this.state.enrolment_boys} name="enrolment_boys" id="enrolment_boys" invalid={this.state.EnrolledBoysState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >

																		</Input>

																		<FormFeedback invalid>
																			The field is required.
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="enrolment_girls">Total Enrolled Girls&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input min="0" type="number" defaultValue={this.state.enrolment_girls} name="enrolment_girls" id="enrolment_girls" invalid={this.state.EnrolledGirlsState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true}>

																		</Input>

																		<FormFeedback invalid>
																			The field is required.
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="attendance_boys">Total Attendance(Boys)&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input min="0" type="number" defaultValue={this.state.attendance_boys} name="attendance_boys" id="attendance_boys" invalid={this.state.AttendanceBoysState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >

																		</Input>

																		<FormFeedback invalid>
																			{this.state.attendanceError}
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="attendance_girls">Total Attendance(Girls)&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input min="0" type="number" defaultValue={this.state.attendance_girls} name="attendance_girls" id="attendance_girls" invalid={this.state.AttendanceGirlsState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >

																		</Input>

																		<FormFeedback invalid>
																			{this.state.attendanceGirlsError}
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>
														</React.Fragment>
													}
												</div>
												<div style={{ marginTop: '3em' }}>
													{this.state.activeStep == 2 &&
														<React.Fragment>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="lead_inspector_id">Lead Inspector Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input type="select" defaultValue={this.state.lead_inspector_id} name="lead_inspector_id" id="lead_inspector_id" invalid={this.state.leadInspectorIdState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true}>
																			<option></option>
																			{
																				Users.map(user => (
																					(user.sector_id == this.state.sector_id && user.user_role == 'Inspector' && user.user_id != this.state.fourth_inspector_id && user.user_id != this.state.second_inspector_id && user.user_id != this.state.third_inspector_id && user.user_id != this.state.first_inspector_id) &&
																					<option value={user.user_id} key={user.user_id}>{user.user_name}</option>
																				)
																				)
																			}
																		</Input>
																		<FormFeedback invalid>
																			The field is required.
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>

															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="first_inspector_id">First Inspector Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input type="select" name="first_inspector_id" defaultValue={this.state.first_inspector_id} id="first_inspector_id" invalid={this.state.firstInspectorIdState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
																			<option></option>
																			{
																				Users.map(user => (
																					(user.sector_id == this.state.sector_id && user.user_role == 'Inspector' && user.user_id != this.state.fourth_inspector_id && user.user_id != this.state.second_inspector_id && user.user_id != this.state.third_inspector_id && user.user_id != this.state.lead_inspector_id) &&
																					<option value={user.user_id} key={user.user_id}>{user.user_name}</option>
																				)
																				)
																			}
																		</Input>
																		<FormFeedback invalid>
																			The field is required.
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="second_inspector_id">Second Inspector Name&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input type="select" name="second_inspector_id" defaultValue={this.state.second_inspector_id} id="second_inspector_id" invalid={this.state.secondInspectorIdState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} >
																			<option></option>
																			{
																				Users.map(user => (
																					(user.sector_id == this.state.sector_id && user.user_role == 'Inspector' && user.user_id != this.state.first_inspector_id && user.user_id != this.state.fourth_inspector_id && user.user_id != this.state.third_inspector_id && user.user_id != this.state.lead_inspector_id) &&
																					<option value={user.user_id} key={user.user_id}>{user.user_name}</option>
																				)
																				)
																			}
																		</Input>
																		<FormFeedback invalid>
																			The field is required.
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="third_inspector_id">Third Inspector Name</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input type="select" name="third_inspector_id" defaultValue={this.state.third_inspector_id} id="third_inspector_id" onChange={this.handleInput} onBlur={this.handleInput} >
																			<option></option>
																			{
																				Users.map(user => (
																					(user.sector_id == this.state.sector_id && user.user_role == 'Inspector' && user.user_id != this.state.first_inspector_id && user.user_id != this.state.second_inspector_id && user.user_id != this.state.fourth_inspector_id && user.user_id != this.state.lead_inspector_id) &&
																					<option value={user.user_id} key={user.user_id}>{user.user_name}</option>
																				)
																				)
																			}
																		</Input>
																	</Col>
																</Row>
															</FormGroup>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="fourth_inspector_id">Fourth Inspector Name</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input type="select" name="fourth_inspector_id" defaultValue={this.state.fourth_inspector_id} id="fourth_inspector_id" onChange={this.handleInput} onBlur={this.handleInput} >
																			<option></option>
																			{
																				Users.map(user => (
																					(user.sector_id == this.state.sector_id && user.user_role == 'Inspector' && user.user_id != this.state.first_inspector_id && user.user_id != this.state.second_inspector_id && user.user_id != this.state.third_inspector_id && user.user_id != this.state.lead_inspector_id) &&
																					<option value={user.user_id} key={user.user_id}>{user.user_name}</option>
																				)
																				)
																			}
																		</Input>
																	</Col>
																</Row>
															</FormGroup>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="present_visitation_date">Present Visitation Date&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input type="date" name="present_visitation_date" id="present_visitation_date" defaultValue={this.state.present_visitation_date} invalid={this.state.presentDateState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
																		<FormFeedback invalid>
																			The field is requied and can not be a date in the future.
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="prev_visitation_date">Previous Visitation Date&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input type="date" name="prev_visitation_date" defaultValue={this.state.prev_visitation_date} id="prev_visitation_date" invalid={this.state.prevDateState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required={true} />
																		<FormFeedback invalid>
																			The field is required and should be a date in the past.
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>
															{/*<FormGroup>
													<Row>
														<Col xs={4} sm={4} md={4} lg={4} xl={4}>
															<Label for="publication_date">Publication Date&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
														</Col>
														<Col xs={8} sm={8} md={8} lg={8} xl={8}>
															<Input type="date" name="publication_date" id="publication_date" invalid={this.state.pubDateState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required />
															<FormFeedback invalid>
																The field is required. It must be greater than the visitation date.
															</FormFeedback>
														</Col>
													</Row>
												</FormGroup>*/}
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="lead_advisor_id">Advisor&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input type="text" name="lead_advisor_id" defaultValue={this.state.lead_advisor_id} id="lead_advisor_id" invalid={this.state.leadAdvisorIdState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required >

																		</Input>
																		<FormFeedback invalid>
																			The field is required.
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>
															<FormGroup>
																<Row>
																	<Col xs={4} sm={4} md={4} lg={4} xl={4}>
																		<Label for="govt_chair_id">School Governance Chair&nbsp;<i className="text-danger">*</i>&nbsp;</Label>
																	</Col>
																	<Col xs={8} sm={8} md={8} lg={8} xl={8}>
																		<Input type="text" name="govt_chair_id" defaultValue={this.state.govt_chair_id} id="govt_chair_id" invalid={this.state.govChairIdState === 'has-danger'} onChange={this.handleInput} onBlur={this.handleInput} required >

																		</Input>
																		<FormFeedback invalid>
																			The field is required.
																		</FormFeedback>
																	</Col>
																</Row>
															</FormGroup>
														</React.Fragment>
													}
												</div>
											</ModalBody>
											<ModalFooter>
												<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
													{this.state.activeStep != 0 &&
														<Button
															color="inherit"
															disabled={this.state.activeStep === 0}
															onClick={handleBack}
															sx={{ mr: 1 }}
														>
															Back
														</Button>
													}
												</Box>
												<Box sx={{ flex: '1 1 auto' }} />
												{this.state.activeStep !== steps.length &&

													<Button color="success" onClick={handleComplete}>
														{completedSteps() === totalSteps() - 1
															? this.state.EditModalVisible ? 'Update' : 'Create'
															: 'Next'}
													</Button>
												}
												<Button color="danger" onClick={this.toggle}>Cancel</Button>
											</ModalFooter>
										</Form>
									</Modal>


									<Table responsive striped size="lg">
										<MaterialTable
											title={this.state.juridistiction.toUpperCase()}
											columns={data.columns}
											data={
												Visits.map(visit => (
													{
														//visit_id:visit.visit_id,
														emis_id: visit.school_name,
														present_visitation_date: new Date(visit.present_visitation_date).toLocaleDateString(),
														//head_teacher:Users.map(user=>((user.user_id==visit.head_teacher)&& user.user_name)),
														head_teacher: visit.head_teacher,
														//phone_number:visit.user_phone,
														lead_inspector_id: Users.map(user => ((user.user_id == visit.lead_inspector_id) ? user.user_name : '')),
														pea: visit.lead_advisor_id,
														//sector:visit.sector_name,
														cluster_id: visit.zone_name,
														district_id: visit.district_name,
														division_id: visit.division_name,
														expiry_date: visit.expiry_date,
														'OPTIONS': <pre>
															{this.state.role == 'Inspector' &&
																<>
																	<Link className={((this.state.role != 'Inspector' || this.state.logged_user_id != visit.lead_inspector_id || new Date(visit.expiry_date) < new Date()) && (this.state.logged_user_id != visit.first_inspector_id) && (this.state.logged_user_id != visit.second_inspector_id)) ? "btn invisible" : "btn"} to={`/inspection_visits/`} onClick={() => this.editInspectionVisit(visit.visit_id)} ><span className='text-success' ><Edit /></span>
																	</Link>

																	&nbsp;&nbsp;<Link className={((this.state.role != 'Inspector' || this.state.logged_user_id != visit.lead_inspector_id || new Date(visit.expiry_date) < new Date()) && (this.state.logged_user_id != visit.first_inspector_id) && (this.state.logged_user_id != visit.second_inspector_id)) ? "btn invisible" : "btn"} to={`/inspection_visits`} onClick={() => this.deleteInspectionVisit(visit.visit_id)} ><span className="text-danger" ><Delete /></span></Link>
																</>
															}
															&nbsp;&nbsp;
															&nbsp;&nbsp;<Link className={this.state.role.toLowerCase() == 'system administrator' ? "btn" : "btn invisible"} onClick={() => this.toggleAdminEdit(visit)} to={`/inspection_visits`}><span className="text-danger" ><Edit /></span></Link>
															&nbsp;&nbsp;
															<Link className={(this.state.role == 'Inspector' || this.state.role=='DSE' || this.state.role=='Division Manager' || this.state.role == 'PEA' || this.state.role == 'Secondary Advisor' || this.state.role == 'Head Teacher') ? "btn btn-primary" : "btn btn-primary invisible"} to={`/inspection_panel/` + visit.visit_id + "&" + (new Date(visit.expiry_date) < new Date()) + "&" + this.state.logged_user_id + "&" + visit.lead_inspector_id + "&" + visit.first_inspector_id} >MORE OPTIONS</Link></pre>
													}
												))}

											options={{
												exportButton: true,
												tableLayout: 'auto',
												loadingType: 'overlay',
												pageSizeOptions: [5, 20, 50, 100, 500, 1000],
												emptyRowsWhenPaging: false,
												columnsButton: true,
												exportAllData: true,
												actionsColumnIndex: -1,
												search: true,
												sorting: true,
											}}

										/>

									</Table>
									<Modal isOpen={this.state.EditAdminModalVisible}>
										<ModalHeader toggle={this.toggleAdminEdit}>
											<center className="justify-content-center">{this.state.visit && this.state.visit.school_name}</center>
										</ModalHeader>
										<Form onSubmit={this.updateExpiryDate} >
											<ModalBody>
												{this.state.showOptions &&
													<>
														<p>WHAT ARE YOU UPDATING ?</p>
														<ListGroup>
															<ListGroupItem style={{ cursor: 'pointer' }} onClick={this.toggleUpdateExpiryDate}>
																Update expiry date
															</ListGroupItem>
														</ListGroup>
													</>
												}
												{this.state.toggleCalendar &&
													<>
														<p style={{ textWeight: 'bold' }}>UPDATE VISIT EXPIRY DATE FOR {this.state.visit && this.state.visit.school_name}</p>
														<FormGroup>
															<div>
																<Input
																	type="date"
																	className="form-control"
																	defaultValue={this.state.expiry_date} name="expiry_date" id="expiry_date" onChange={this.handleInput} onBlur={this.handleInput} required={true}
																/>
															</div>
														</FormGroup>
													</>
												}
											</ModalBody>
											<ModalFooter>
												<Button type="Submit" color="success">Update</Button>
												<Button color="danger" onClick={this.toggleAdminEdit}>Cancel</Button>
											</ModalFooter>
										</Form>
									</Modal>
								</div>
							</div>
						</div>
					</div>


				</div>
			</React.Fragment >
		);
	}
}

export default InspectionVisits;